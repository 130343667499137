// gatsby-browser.js

import "./src/styles/global.css";
import Lenis from '@studio-freight/lenis';

export const onInitialClientRender = () => {
  // Initialize Lenis for smooth scrolling
  const lenis = new Lenis({
    duration: 1.2,
    smoothWheel: true,
    smoothTouch: false,
    direction: 'vertical',
  });

  function raf(time) {
    lenis.raf(time);
    requestAnimationFrame(raf);
  }

  requestAnimationFrame(raf);

  // Delay the loading of Tawk.to script
  setTimeout(() => {
    var Tawk_API = Tawk_API || {},
      Tawk_LoadStart = new Date();
    (function () {
      var s1 = document.createElement("script"),
        s0 = document.getElementsByTagName("script")[0];
      s1.async = true;
      // Ensure the script is loaded over HTTPS to avoid the 'sendBeacon' error
      s1.src = "https://embed.tawk.to/66d5bb3850c10f7a00a3121b/1i6pe6luh";
      s1.charset = "UTF-8";
      s1.setAttribute("crossorigin", "*");
      s0.parentNode.insertBefore(s1, s0);
    })();
  }, 3000); // Load script 3 seconds after initial render

  // Make Tawk_API available globally
  window.Tawk_API = window.Tawk_API || {};
};
